import React from 'react'
import ActionPoint from './ActionScreen/ActionPoint'
import AssetOverlay from './ActionScreen/AssetOverlay'
import InfoText from './ActionScreen/InfoText'

function ActionScreen({
  position = { x: 0, y: 0 },
  direction = 'right',
  actionPoints = [],
  assetOverlays = [],
  infoText = {},
  isVisible = true,
  interactPrompt = false,
}) {
  return (
    <div
      className={`action_screen ${isVisible ? 'visible' : 'hidden'}`}
      style={{ left: 100 * position.x + '%', top: 100 * position.y + '%' }}
    >
      {assetOverlays.map((assetOverlay, index) => (
        <AssetOverlay
          position={assetOverlay.position}
          size={assetOverlay.size}
          animationData={assetOverlay.animationData}
          behavior={assetOverlay.behavior}
          key={index}
        />
      ))}
      {actionPoints.map((actionPoint, index) => (
        <ActionPoint
          position={actionPoint.position}
          content={actionPoint.content}
          key={index}
        />
      ))}
      {infoText?.text && (
        <InfoText text={infoText.text} position={infoText.position} />
      )}
      {interactPrompt && <div className={`swipe_prompt go_${direction}`}></div>}
    </div>
  )
}

export default ActionScreen
