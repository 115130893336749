import create from 'zustand'

const useStore = create((set, get) => ({
  sidebarContent: [],
  setSidebarContent: (sidebarContent) => {
    set({ sidebarContent, showSidebar: true })
  },
  showSidebar: false,
  setShowSidebar: (showSidebar) => {
    set({ showSidebar })
  },
}))

export default useStore
