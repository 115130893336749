import lottie from 'lottie-web'
import { useEffect, useState } from 'react'
import wasserkreislauf from './assets/lottie/wasserkreislauf.json'
import { motion } from 'framer-motion'

import './App.scss'
import animMeta from './assets/animMeta'
import ActionScreens from './components/ActionScreens'
import Sidebar from './components/Sidebar'
import ProgressBar from './components/ProgressBar'
import PlayButton from './components/PlayButton'

const initialVelocityDecreaseFactor = 100
const velocityCutOff = 0.001
const decayFactor = 0.97
const slowDownThreshold = 0.5
const animSpeed = 0.02 // Geschwindigkeit wenn Play gedrückt wird, oder man zu Sektion skippt
const renderInterval = 1000 / 60 // 60 FPS

const getPanDirection = ({ progress }) => {
  const screens = animMeta.screens
  const currentScreen = Math.floor(progress)
  return screens[currentScreen]?.direction
}

const getProgressByPanDirection = ({
  panDirection,
  panDelta,
  animDimensions,
}) => {
  const deltaX = panDelta.x
  const deltaY = panDelta.y
  let progressDelta = 0
  switch (panDirection) {
    case 'up':
      progressDelta = deltaY / animDimensions.height
      break
    case 'right':
      progressDelta = (deltaX * -1) / animDimensions.width
      break
    case 'down':
      progressDelta = (deltaY * -1) / animDimensions.height
      break
    case 'left':
      progressDelta = deltaX / animDimensions.width
      break
    default:
      break
  }
  return progressDelta
}

function App() {
  const [animationProgress, setAnimationProgressState] = useState(0) // Todo: useRef() um Rerender zu verhindern
  const [animDimensions, setAnimDimensions] = useState({ width: 0, height: 0 })
  const [interruptProgress, setInterruptProgress] = useState(false)

  const [intervalId, setIntervalId] = useState(-1)
  const [anim, setAnim] = useState()

  const setAnimationProgress = (inputProgress) => {
    const outputProgress =
      inputProgress < 0
        ? 0
        : inputProgress > animMeta.screens.length
        ? 0
        : inputProgress
    setAnimationProgressState(outputProgress)
  }

  const onTapStart = () => {
    unsetAnimationInterval()
  }

  const unsetAnimationInterval = () => {
    if (intervalId) clearInterval(intervalId)
  }

  const onPan = (event, info) => {
    const { delta } = info
    const panDirection = getPanDirection({ progress: animationProgress })
    const progressDelta = getProgressByPanDirection({
      panDirection,
      panDelta: delta,
      animDimensions,
    })
    const progress = animationProgress + progressDelta
    const newPanDirection = getPanDirection({ progress })
    if (newPanDirection !== panDirection) {
      setInterruptProgress(true)
    }
    if (!interruptProgress) setAnimationProgress(progress)
  }

  const onPanEnd = (event, info) => {
    const { velocity } = info
    velocityDecrease({ velocity })
    setInterruptProgress(false)
  }

  const velocityDecrease = ({ velocity }) => {
    let progress = animationProgress
    let velocityX = velocity.x / initialVelocityDecreaseFactor
    let velocityY = velocity.y / initialVelocityDecreaseFactor
    // Directional lock
    velocityX = Math.abs(velocityX) > Math.abs(velocityY) ? velocityX : 0
    velocityY = Math.abs(velocityY) > Math.abs(velocityX) ? velocityY : 0
    // Pan Direction initial bestimmen, um später Änderungen zu erkennen
    const initialPanDirection = getPanDirection({ progress })
    // Interval
    const id = setInterval(() => {
      if (
        Math.abs(velocityX) > velocityCutOff ||
        Math.abs(velocityY) > velocityCutOff
      ) {
        velocityX = velocityX * decayFactor
        velocityY = velocityY * decayFactor
        const panDelta = { x: velocityX, y: velocityY }
        const panDirection = getPanDirection({ progress })
        // Bestimmen, ob sich die Pan-Direction geändert hat, um die Geschwindigkeit auf 0 zu setzen
        const panDirectionHasChanged = panDirection !== initialPanDirection
        const progressDelta = panDirectionHasChanged
          ? 0
          : getProgressByPanDirection({
              panDirection,
              panDelta,
              animDimensions,
            })
        const progressWithDelta = progress + progressDelta
        progress = progressWithDelta < 0 ? 0 : progressWithDelta
        setAnimationProgress(progress)
      }
    }, renderInterval)
    unsetAnimationInterval()
    setIntervalId(id)
  }

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: document.querySelector('#anim'),
      animationData: wasserkreislauf,
      autoplay: false,
    })
    setAnim(anim)
    const { aspect } = animMeta
    const width =
      window.innerWidth / aspect > window.innerHeight
        ? window.innerWidth
        : window.innerHeight * aspect
    const height =
      window.innerHeight * aspect > window.innerWidth
        ? window.innerHeight
        : window.innerWidth / aspect
    setAnimDimensions({ width, height })
  }, [])

  const animateTo = ({ index }) => {
    let progress = animationProgress
    let speed = animSpeed
    const playsForwards = index - progress > 0
    // Interval
    const id = setInterval(() => {
      const difference = index - progress
      if (Math.abs(difference) < slowDownThreshold) {
        speed = speed * decayFactor
      }
      // Todo: nochmal cleaner schreiben
      progress =
        playsForwards && progress > index
          ? index
          : !playsForwards && progress < index
          ? index
          : playsForwards
          ? progress + speed
          : progress - speed
      if (Math.abs(difference) > velocityCutOff) {
        setAnimationProgress(progress)
      } else {
        clearInterval(id)
      }
    }, renderInterval)
    unsetAnimationInterval()
    setIntervalId(id)
  }

  anim?.goToAndStop(animationProgress * 1000)

  return (
    <>
      <motion.div
        className={`app ${animationProgress === 0 ? 'start' : ''}`}
        onTapStart={onTapStart}
        onPan={onPan}
        onPanEnd={onPanEnd}
        style={{ width: animDimensions.width, height: animDimensions.height }}
      >
        <div id='anim' className='anim'></div>
        <ActionScreens
          animationProgress={animationProgress}
          animDimensions={animDimensions}
        />
      </motion.div>
      <ProgressBar
        animationLength={animMeta.screens.length}
        animationProgress={animationProgress}
        animateTo={animateTo}
      />
      <PlayButton
        onClick={() => animateTo({ index: Math.floor(animationProgress) + 1 })}
      />
      <Sidebar />
    </>
  )
}

export default App
