import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web'

const SidebarAnim = ({ animationData }) => {
  const animContainer = useRef()
  useEffect(() => {
    lottie.loadAnimation({
      container: animContainer?.current,
      animationData: animationData,
      autoplay: true,
      loop: true,
    })
  }, [])
  return <div ref={animContainer}></div>
}

export default SidebarAnim
