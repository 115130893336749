import React, { useEffect, useState } from 'react'
import ActionScreen from './ActionScreen'

import animMeta from '../assets/animMeta'

const getOffsetPosition = ({ animationProgress }) => {
  const progress = animationProgress
  const screens = animMeta.screens
  const currentScreen = Math.floor(progress)
  const currentScreenProgress = animationProgress - currentScreen
  const currentScreenPosition = screens[currentScreen]?.position
  const panDirection = screens[currentScreen]?.direction
  let offsetPosition = { x: 0, y: 0 }
  switch (panDirection) {
    case 'up':
      offsetPosition = {
        y: currentScreenPosition.y - currentScreenProgress,
      }
      break
    case 'right':
      offsetPosition = {
        x: currentScreenPosition.x + currentScreenProgress,
      }
      break
    case 'down':
      offsetPosition = {
        y: currentScreenPosition.y + currentScreenProgress,
      }
      break
    case 'left':
      offsetPosition = { x: currentScreenPosition.x - currentScreenProgress }
      break
    default:
      break
  }
  return { ...currentScreenPosition, ...offsetPosition }
}

const ActionScreens = ({ animationProgress, animDimensions }) => {
  const data = animMeta.screens
  const [screens, setScreens] = useState([])
  const offsetPosition = getOffsetPosition({ animationProgress })
  // TODO: nur aktuellen, vorigen und nächsten Screen rendern
  useEffect(() => {
    const screens = []
    data.forEach((screen, index) => {
      // Overlays werden maximal bis 1.5 screens vorher und hinterher gerendert
      const isVisible =
        index - 1 < animationProgress && index + 1.5 > animationProgress
      screens.push(
        <ActionScreen
          key={index}
          actionPoints={screen?.actionPoints}
          assetOverlays={screen?.assetOverlays}
          infoText={screen?.infoText}
          direction={screen?.direction}
          interactPrompt={screen?.interactPrompt}
          position={screen?.position}
          isVisible={isVisible}
        />
      )
      setScreens(screens)
    })
  }, [animationProgress])
  return (
    <div
      className='action_screens'
      style={{
        width: animDimensions.width,
        height: animDimensions.height,
        transform: `translate(${offsetPosition.x * 100 * -1}%, ${
          offsetPosition.y * 100 * -1
        }%)`,
      }}
    >
      {screens}
    </div>
  )
}

export default ActionScreens
