import React from 'react'

function InfoText({ text = '', position = { x: 0.5, y: 0.5 } }) {
  const { x, y } = position
  return (
    <h1
      className='info_text'
      style={{ top: `${y * 100}%`, left: `${x * 100}%` }}
    >
      {text}
    </h1>
  )
}

export default InfoText
