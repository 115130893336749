import React from 'react'
import ProgressBarSegment from './ProgressBar/ProgressBarSegment'

const ProgressBar = ({
  animationLength = 0,
  animationProgress = 0,
  animateTo = () => {},
}) => {
  const progressBarSegments = []
  for (let i = 0; i < animationLength; i++) {
    const currentProgress = animationProgress - i
    const currentProgressLimited =
      currentProgress < 0 ? 0 : currentProgress > 1 ? 1 : currentProgress
    progressBarSegments.push(
      <ProgressBarSegment
        key={i}
        progress={currentProgressLimited}
        onClick={() => animateTo({ index: i })}
      />
    )
  }
  return <div className='progress_bar'>{progressBarSegments}</div>
}

export default ProgressBar
