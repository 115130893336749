import React from 'react'

const ProgressBarSegment = ({ progress, onClick = null }) => {
  return (
    <div className='progress_bar_segment' onClick={onClick}>
      <div
        className='progress_bar_segment_fill'
        style={{ width: `${progress * 100}%` }}
      />
    </div>
  )
}

export default ProgressBarSegment
