// Animation
import boot from './lottie/boot.json'
import boot_2 from './lottie/boot_2.json'
import teekessel from './lottie/teekessel.json'
import verdunstung from './lottie/verdunstung.json'
import regen from './lottie/regen.json'
import rocks from './lottie/rocks.json'
import rauch from './lottie/rauch.json'
// Images
import kondensation from '../assets/img/kondensation.jpg'
import fluss from '../assets/img/fluss.jpg'
import aggregatzustaende from '../assets/img/aggregatzustaende.svg'

const animMeta = {
  type: 'loop',
  aspect: 4 / 3,
  screens: [
    {
      direction: 'right', // Richtung in die man von diesem Screen aus weiter kommt
      position: { x: 0, y: 0 },
      infoText: {
        text: 'Zuerst brauchst du Sonne, um den Kreislauf zu starten. Schiebe die Wolken weg!',
        position: { x: 0.5, y: 0.3 },
      },
      interactPrompt: true,
    },
    {
      position: { x: 1, y: 0 },
      direction: 'right',
    },
    {
      position: { x: 2, y: 0 },
      direction: 'up',
      infoText: {
        text: 'Das Wasser verdunstet in der Hitze der Sonne',
      },
      assetOverlays: [
        {
          position: { x: 0.5, y: 0 },
          size: { width: 1, height: 2 },
          animationData: verdunstung,
        },
        {
          position: { x: 0.1, y: 0.8 },
          size: { width: 0.2, height: 0.2 },
          animationData: boot,
        },
      ],
    },
    {
      position: { x: 2, y: -1 },
      direction: 'up',
      actionPoints: [
        {
          position: { x: 0.5, y: 0.8 },
          content: [
            { type: 'anim', animationData: teekessel },
            {
              type: 'headline',
              text: 'Wie sich Wolken bilden',
            },
            {
              type: 'paragraph',
              text: 'Wasser gibt es nicht nur im flüssigen Zustand. Abhängig von der Temperatur nimmt Wasser unterschiedliche Zustände an.',
            },
            { type: 'image', url: aggregatzustaende },
            {
              type: 'paragraph',
              text: 'Du kennst es sicherlich auch im gefrorenen Zustand als Eiswürfel in deinem Gefrierfach oder den Schnee im Winter. Wenn Wasser erhitzt wird, geht es in den gasförmigen Zustand über in Form von Wasserdampf. Das siehst du zum Beispiel beim Duschen oder wenn du Wasser in einem Wasserkocher erhitzt.',
            },
            {
              type: 'headline',
              text: 'Das Wasser verdampft durch die Hitze der Sonne',
            },
            {
              type: 'paragraph',
              text: 'Wenn die warmen Sonnenstrahlen das Wasser treffen, verdampft es und steigt nach oben auf. Der Wasserdampf ist für dich meistens unsichtbar, da er anders als beim Wasserkocher über eine große Fläche verteilt aufsteigt.',
            },
            {
              type: 'paragraph',
              text: 'Das Wasser verdunstet nicht nur aus wie in unserem Beispiel aus Seen: Auch Flüsse, das Meer, Pflanzen oder deine trocknende Wäsche geben Feuchtigkeit in die Atmosphäre ab. Die Meere leisten aufgrund ihrer großen Fläche den größten Anteil.',
            },
          ],
        },
      ],
    },
    {
      position: { x: 2, y: -2 },
      direction: 'down',
      infoText: {
        text: '...und kondensiert in der kalten Luft wieder',
        position: { x: 0.5, y: 0.7 },
      },
      actionPoints: [
        {
          position: { x: 0.5, y: 0.5 },
          content: [
            { type: 'image', url: kondensation },
            {
              type: 'headline',
              text: 'Das Wasser kondensiert wie an einer kalten Scheibe',
            },
            {
              type: 'paragraph',
              text: 'Das aufsteigende Wasser kommt in immer kältere Luftschichten umso höher es steigt. Dadurch kondensiert es wieder. Das heißt es geht zurück in den flüssigen Zustand.',
            },
            {
              type: 'paragraph',
              text: 'Hast du jemals gegen eine kalte Scheibe gehaucht? Wenn sie beschlägt bildet sich aus deinem Atem winzige Wassertropfen. In der Atmosphäre passiert genau das gleiche mit dem Wasserdampf.',
            },
            {
              type: 'headline',
              text: 'Die Tropfen fallen als Regen wieder auf die Erde',
            },
            {
              type: 'paragraph',
              text: 'Das kondensierte Wasser siehst du als Wolken am Himmel. Überschreiten die Wassertropfen eine gewisse Größe, kommen sie als Regen, Schnee oder Hagel zurück auf die Erde gefallen.',
            },
          ],
        },
      ],
    },
    {
      position: { x: 2, y: -1 },
      direction: 'left',
      actionPoints: [
        {
          position: { x: 0.5, y: 0.65 },
          content: [
            { type: 'image', url: fluss },
            {
              type: 'headline',
              text: 'Als Niederschlag kehrt das Wasser zurück',
            },
            {
              type: 'paragraph',
              text: 'Das Wasser regnet oder schneit zurück auf die Erde. Manchmal fließt das Wasser direkt in ein Gewässer wie einen Fluss oder See zurück. In anderen Fällen friert es in Polkappen von Bergen ein und kann dort Jahrtausende verweilen. Am häufigsten sickert das Wasser jedoch in den Boden und wird teil des Grundwassers, das ebenfalls in Form von Quellen Flüsse und Seen speist.',
            },
            {
              type: 'paragraph',
              text: 'So schließt sich der Kreislauf und das Wasser kann erneut verdunsten.',
            },
          ],
        },
      ],
      assetOverlays: [
        {
          position: { x: 0.5, y: 0.5 },
          size: { width: 1, height: 1 },
          animationData: regen,
        },
        {
          position: { x: 0.272, y: 0.422 },
          size: { width: 0.2, height: 0.2 },
          animationData: rauch,
        },
      ],
    },
    {
      position: { x: 1, y: -1 },
      direction: 'left',
      assetOverlays: [
        {
          position: { x: 0.5, y: 0.5 },
          size: { width: 1, height: 1 },
          animationData: regen,
        },
        {
          position: { x: 0.85, y: 0.875 },
          size: { width: 0.6, height: 0.5 },
          animationData: rocks,
        },
      ],
    },
    {
      position: { x: 0, y: -1 },
      direction: 'left',
      infoText: {
        text: 'So beginnt der Kreislauf von neuem',
        position: { x: 0.5, y: 0.3 },
      },
      interactPrompt: true,
      assetOverlays: [
        {
          position: { x: 0.7, y: 0.8 },
          size: { width: 0.2, height: 0.2 },
          animationData: boot_2,
        },
        {
          position: { x: 0.5, y: 0.5 },
          size: { width: 1, height: 1 },
          animationData: regen,
        },
      ],
    },
  ],
}

export default animMeta
