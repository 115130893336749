import React, { useState, useRef, useEffect } from 'react'
import lottie from 'lottie-web'

const AssetOverlay = ({
  type,
  position,
  size,
  animationData,
  behavior = 'autoplay',
}) => {
  const [anim, setAnim] = useState()
  const animContainer = useRef()

  const { x, y } = position
  const { width, height } = size

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animContainer?.current,
      animationData: animationData,
      autoplay: behavior === 'autoplay',
      loop: behavior === 'autoplay',
    })
    setAnim(anim)
  }, [])

  return (
    <div
      ref={animContainer}
      className='asset_overlay'
      style={{
        top: `${y * 100}%`,
        left: `${x * 100}%`,
        width: `${width * 100}%`,
        height: `${height * 100}%`,
      }}
    ></div>
  )
}

export default AssetOverlay
