import React from 'react'
import playIcon from '../assets/icons/icon-play.svg'

const PlayButton = ({ onClick = () => {} }) => {
  return (
    <div className='play_button' onClick={onClick}>
      <img src={playIcon} alt='play' className='icon play' />
    </div>
  )
}

export default PlayButton
