import React from 'react'
import useStore from '../../store/store'

const ActionPoint = ({ position, content }) => {
  const { setSidebarContent } = useStore()

  const onClick = () => {
    setSidebarContent(content)
  }

  const { x, y } = position
  return (
    <div
      className='action_point'
      style={{ top: `${y * 100}%`, left: `${x * 100}%` }}
      onClick={onClick}
    ></div>
  )
}

export default ActionPoint
