import React, { useEffect, useState } from 'react'
import useStore from '../store/store'
import SidebarAnim from './Sidebar/SidebarAnim'
import closeIcon from '../assets/icons/icon-sidebar-close.svg'

const Sidebar = () => {
  const { sidebarContent, showSidebar, setShowSidebar } = useStore()
  const [content, setContent] = useState()

  const onClick = (e) => {
    setShowSidebar(false)
  }

  useEffect(() => {
    if (sidebarContent) {
      const contentArray = []
      sidebarContent.forEach((sidebarModule, index) => {
        const { type, text, alt, url, animationData } = sidebarModule
        switch (type) {
          case 'headline':
            contentArray.push(<h2 key={index}>{text}</h2>)
            break
          case 'paragraph':
            contentArray.push(<p key={index}>{text}</p>)
            break
          case 'anim':
            contentArray.push(
              <SidebarAnim animationData={animationData} key={index} />
            )
            break
          case 'image':
            contentArray.push(<img src={url} alt={alt} key={index} />)
            break
          default:
            break
        }
      })
      setContent(contentArray)
    }
  }, [sidebarContent])
  return (
    <div
      className={`sidebar_wrapper ${showSidebar ? 'open' : ''}`}
      // onClick={onClick}
    >
      <div className='sidebar_overlay' onClick={onClick} />
      <div className={`sidebar`}>
        <img
          src={closeIcon}
          className='icon sidebar_close'
          onClick={onClick}
          alt='Sidebar schließen'
        />
        {content}
      </div>
    </div>
  )
}

export default Sidebar
